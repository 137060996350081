import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 16; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/24-aug-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }
  return imgArray;
})();

export default function GlobalClimateAwakening() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Class IV Presentation - Global Climate Awakening
        </h1>

        <p>
          The class IV students of Chinmaya Vidyalaya , New Delhi, showcased a
          stage presentation on 18th August '23 that encapsulated a
          thought-provoking discussion on raising awareness about protecting the
          environment, a skit on the human activities that result into depletion
          of our natural resources,a mesmerizing group song on preserving the
          environment and a dance that appreciated the beauty of nature and the
          need to preserve it.
          <br />
          <br />
          The Primary Headmistress, Ms Archana Gaur, addressed the gathering and
          sensitized everyone on the significance of preserving the environment.
          The Principal of the school, Ms Archana Soni, in her address, thanked
          the parents and shed light on their role in spreading awareness in
          children in order to keep the planet green and clean.
          <br />
          <br />
          The presentation served as a catalyst to spread awareness on
          environmental issues for a brighter and greener future.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
